<template>
  <van-pull-refresh v-model="loading" @refresh="onRefresh">
    <div class="rank-list">
      <topbar title="排行榜" background="#E7141A" color="#fff" />
      <div class="topThree" v-if="tab.list[tab.active].length">
        <div class="item" v-if="tab.list[tab.active].length >= 2">
          <img class="crown" src="@/assets/twoCrown.png" />
          <img class="avatar" :src="tab.list[tab.active][1].avatarUrl" />
          <img class="index" src="@/assets/two.png" />
        </div>
        <div class="first" v-if="tab.list[tab.active].length >= 1">
          <img class="crown" src="@/assets/firstCrown.png" />
          <img class="avatar" :src="tab.list[tab.active][0].avatarUrl" />
          <img class="index" src="@/assets/first.png" />
        </div>
        <div class="item" v-if="tab.list[tab.active].length >= 3">
          <img class="crown" src="@/assets/thirdCrown.png" />
          <img class="avatar" :src="tab.list[tab.active][2].avatarUrl" />
          <img class="index" src="@/assets/third.png" />
        </div>
      </div>
      <van-tabs v-model="tab.active">
        <van-tab :title="item" v-for="(item, tabIndex) in tab.label" :key="item">
          <div class="list">
            <div class="item my-rank" v-if="tab.list[tab.active].length">
              <div class="user">
                <span class="sq"> {{ self.rank }}</span>
                <img class="avatar" :src="userInfo.avatarUrl" />
                <span class="name">{{ userInfo.nickname }}</span>
              </div>
              <div class="money">
                <span>{{ self.money }}</span> 元
              </div>
            </div>
            <div class="item" v-for="(item, index) in tab.list[tabIndex]" :key="index">
              <div class="user">
                <template v-if="index < 3">
                  <img v-if="index === 0" class="icon" src="@/assets/icon/rank_1.png" alt="" />
                  <img v-if="index === 1" class="icon" src="@/assets/icon/rank_2.png" alt="" />
                  <img v-if="index === 2" class="icon" src="@/assets/icon/rank_3.png" alt="" />
                </template>
                <span v-else class="sq"> {{ index + 1 }} </span>
                <img class="avatar" :src="item.avatarUrl" />
                <span class="name">{{ item.nickname }}</span>
              </div>
              <div class="money">
                <span>{{ item.money }}</span> 元
              </div>
            </div>
            <div
              v-if="!tab.list[tab.active].length"
              style="text-align: center; margin-top: 100px; padding: 50px 0; color: #999"
            >
              暂无数据
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </van-pull-refresh>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tab: {
        label: ['周榜', '月榜', '总榜'],
        list: { 0: [], 1: [], 2: [] },
        active: 0
      },
      self: {
        rank: 0,
        money: 0
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters['user/userInfo']
    },
    isMiniApp() {
      return this.$store.getters['sys/isBmMiniApp']
    }
  },
  mounted() {
    this.getRank()
  },
  watch: {
    'tab.active'() {
      this.getRank()
    }
  },
  methods: {
    onRefresh() {
      this.getRank()
    },
    getRank() {
      let type = 'weekend'
      if (this.tab.active === 1) type = 'month'
      if (this.tab.active === 2) type = 'total'
      this.loading = true
      this.$http
        .get(
          `boom-center-statistics-service/rankStatistics/distribution/bill/${type}/${this.userInfo.id}`
        )
        .then(res => {
          this.tab.list[this.tab.active] = res.distributionBillDTOList || []
          this.self = {
            rank: res.selfRank,
            money: res.selfMoney
          }
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="less" scope>
.rank-list {
  min-height: 100vh;
  .topThree {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    background: #e7141a;
    .first,
    .item {
      margin-left: 20px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      margin-top: 40px;
      .crown {
        width: 40px;
        height: 40px;
        z-index: 1;
        margin-bottom: 10px;
      }
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-top: -12px;
      }
      .index {
        width: auto;
        height: 40px;
        z-index: 1;
        margin-top: -18px;
        object-fit: cover;
      }
    }
    .first {
      margin-top: 10px;
    }
  }
  .type {
    display: flex;
    padding-bottom: 20px;
    justify-content: space-around;
    color: #fff;
    background: #e7141a;
  }
  .van-tabs__wrap {
    background: #e7141a;
    position: sticky;
    top: 59px;
    .van-tabs__nav {
      background: transparent;
      .van-tab {
        color: #fff !important;
      }
    }
    .van-tabs__line {
      background: transparent;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: #fff;
      bottom: 14px;
    }
  }
  .list {
    .my-rank {
      padding: 20px 20px !important;
      border-bottom: 20px solid #f9f9f9;
    }
    .item {
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon,
      .sq {
        width: 20px;
        height: 20px;
        text-align: center;
        color: #ff9966;
        font-size: 13px;
      }
      .user {
        flex: 1;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-direction: initial;
        .avatar {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          margin: 0 10px;
        }
        .name {
          font-size: 15px;
          font-weight: bold;
          color: #555555;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .money {
        font-size: 10px;
        color: #e7141a;
        span {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
